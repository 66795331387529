.housekeeping {
  .grid--elem {
    width: 100%;
    max-width: 35rem;
  }
  .value {
    svg#washing {
      margin-top: 1.5rem;
      width: 5.5rem;
      fill: var(--status-critical);
      transition: fill 1s;
      animation: none;

      &.mustwash {
        fill: var(--status-ok);
        animation: washingblink 2s ease infinite;
      }

      &.wash {
        fill: var(--status-ok);
        animation: none;
      }

      &.canwash {
        fill: var(--status-moderate);
        animation: none;
      }

      &.dontwash {
        fill: var(--status-critical);
        animation: none;
      }
    }
  }
}

@keyframes washingblink {
  0% {
    opacity: 0.8;
    transform: scale(1) rotate(0deg);
  }
  50% {
    opacity: 0.8;
    transform: scale(1) rotate(0deg);
  }
  60% {
    opacity: 1;
    transform: scale(1.1) rotate(5deg);
  }
  70% {
    opacity: 1;
    transform: scale(1) rotate(-5deg);
  }
  80% {
    opacity: 1;
    transform: scale(1.1) rotate(5deg);
  }
  100% {
    opacity: 0.8;
    transform: scale(1) rotate(0deg);
  }
}
