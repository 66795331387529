.toggle-switch-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    height: 100%;
    width: 48px;

    .toggle-switch {
        display: inline-block;
        height: 14px;
        position: relative;
        width: 32px;

        input {
            display: none;

            &:checked + .slider {
                background-color: $main-color-darkened;
            }

            &:checked + .slider:before {
                transform: translate3d(15px, -50%, 0);
            }
        }
    }

    .slider {
        background-color: rgba(150, 150, 150, 0.2);
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: background-color 0.4s;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            height: 17px;
            width: 17px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

            transition: transform 0.4s;
        }

        &.round {
            border-radius: 4px;

            &::before {
                border-radius: 4px;
            }
        }
    }
}
