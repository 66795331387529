.name {
  position: absolute;
  display: flex !important;
  align-items: center;
  padding-right: 1rem !important;

  a {
    pointer-events: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.175rem;
    height: 1.175rem;
    margin-right: 0.5rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.public {
  margin-left: auto;
  opacity: 0.35;
  font-size: 0.75rem;
}
