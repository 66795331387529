/*------------------------------------*\
    FONTS
\*------------------------------------*/

$montserrat: "Montserrat", system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
$ubuntu: "Ubuntu", system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
$nunito: "Nunito", system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;

/*------------------------------------*\
    MEDIA QUERIES
\*------------------------------------*/

$small: "only screen and (max-width: 600px)";
$phone: "only screen and (max-width: 900px)";
$medium: "only screen and (max-width: 1200px)";

/*------------------------------------*\
    COLOR SCHEME
\*------------------------------------*/

/* THEMING */
$main-color: #6ada87;
$main-color-dark: #6ada87;
$main-color-darkened: #66bb6a;
$text-color: #202020;
$text-color-dark: #fff;
$bg-color: #f8f8fa;
$bg-color-dark: linear-gradient(180deg, #404040, #151515 25rem), #151515;

$accent-1: #fff;
$accent-2: #fafafa;
$accent-3: #f5f5f5;
$accent-4: #f1f1f1;
$accent-5: #eaeaea;
$accent-6: #e6e6e6;
$accent-7: #e2e2e2;
$accent-8: #ddd;
$accent-9: #ccc;
$accent-10: #bbb;
$accent-11: #aaa;

$accent-1-dark: #000;
$accent-2-dark: #050505;
$accent-3-dark: #101010;
$accent-4-dark: #151515;
$accent-5-dark: #202020;
$accent-6-dark: #272727;
$accent-7-dark: #353535;
$accent-8-dark: #444;
$accent-9-dark: #666;
$accent-10-dark: #888;
$accent-11-dark: #aaa;

/* Header */
$header-color: #151515;
$header-color-dark: #fff;
$header-bg-color: #fff;
$header-bg-color-dark: #252525;

/* Sidebar */
$sidebar-color: #151515;
$sidebar-color-dark: #fff;
$sidebar-bg-color: #fff;
$sidebar-bg-color-dark: #252525;
$sidebar-secondary-color: #151515;
$sidebar-secondary-color-dark: #151515;
$sidebar-secondary-bg-color: #fff;
$sidebar-secondary-bg-color-dark: #202020;

/* Old Navbar */
$navbar-bg-color: #fff;
$navbar-bg-color-dark: #111111;
$navbar-elem-color: #fff;
$navbar-elem-color-dark: #111111;
$navbar-elem-active: #fcfcfc;
$navbar-elem-active-dark: #131313;
$navbar-child-elem-color: #fff;
$navbar-child-elem-color-dark: #131313;
$navbar-child-elem-active: #fafafa;
$navbar-child-elem-active-dark: #161616;

/* Grid */
$grid-bg-color: #fff;
$grid-bg-color-dark: #202020;
$unit-color: #bbb;
$unit-color-dark: #ccc;
$info-color: #ccc;
$info-color-dark: #ccc;

/* Shadows */
$std-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.25);
$std-shadow-dark: 0 0 25px -10px rgba(0, 0, 0, 0.25);
$lt-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.15);
$lt-shadow-dark: none;

/* Status */
$status-std: #101010;
$status-std-dark: #fff;
$status-ok: #6ada87;
$status-ok-fill: #57d15d;
$status-ok-dark: #6ada87;
$status-moderate: #e2c93e;
$status-moderate-fill: #f5a42a;
$status-moderate-dark: #e2c93e;
$status-critical: #e23e3e;
$status-critical-fill: #f13232;
$status-critical-dark: #e23e3e;
$bhkw-opacity: 0.04;
$gear-color: #f2f2f2;
$gear-color-dark: #404040;
$flash-color: #f2f2f2;
$flash-color-dark: #404040;
$battery-active: #6ada87;
$battery-active-dark: #50b86c;
$battery-inactive: #f2f2f2;
$battery-inactive-dark: #404040;
$overlay-opacity: rgba(0, 0, 0, 0.4);
$overlay-opacity-dark: rgba(0, 0, 0, 0.7);

:root {
  --main-color: #{$main-color};
  --text-color: #{$text-color};
  --bg-color: #{$bg-color};

  --accent-1: #{$accent-1};
  --accent-2: #{$accent-2};
  --accent-3: #{$accent-3};
  --accent-4: #{$accent-4};
  --accent-5: #{$accent-5};
  --accent-6: #{$accent-6};
  --accent-7: #{$accent-7};
  --accent-8: #{$accent-8};
  --accent-9: #{$accent-9};
  --accent-10: #{$accent-10};
  --accent-11: #{$accent-11};

  --header-color: #{$header-color};
  --header-bg-color: #{$header-bg-color};

  --sidebar-color: #{$sidebar-color};
  --sidebar-bg-color: #{$sidebar-bg-color};
  --sidebar-secondary-color: #{$sidebar-secondary-color};
  --sidebar-secondary-bg-color: #{$sidebar-secondary-bg-color};

  --navbar-bg-color: #{$navbar-bg-color};
  --navbar-elem-color: #{$navbar-elem-color};
  --navbar-elem-active: #{$navbar-elem-active};
  --navbar-child-elem-color: #{$navbar-child-elem-color};
  --navbar-child-elem-active: #{$navbar-child-elem-active};

  --grid-bg-color: #{$grid-bg-color};
  --unit-color: #{$unit-color};
  --info-color: #{$info-color};

  --std-shadow: #{$std-shadow};
  --lt-shadow: #{$lt-shadow};

  --status-std: #{$status-std};

  --status-ok: #{$status-ok};
  --status-ok-fill: #{$status-ok-fill};
  --status-moderate: #{$status-moderate};
  --status-moderate-fill: #{$status-moderate-fill};
  --status-critical: #{$status-critical};
  --status-critical-fill: #{$status-critical-fill};

  --gear-color: #{$gear-color};
  --flash-color: #{$flash-color};
  --battery-active: #{$battery-active};
  --battery-inactive: #{$battery-inactive};
  --overlay-opacity: #{$overlay-opacity};
}

[data-theme="dark"] {
  --main-color: #{$main-color-dark};
  --text-color: #{$text-color-dark};
  --bg-color: #{$bg-color-dark};

  --accent-1: #{$accent-1-dark};
  --accent-2: #{$accent-2-dark};
  --accent-3: #{$accent-3-dark};
  --accent-4: #{$accent-4-dark};
  --accent-5: #{$accent-5-dark};
  --accent-6: #{$accent-6-dark};
  --accent-7: #{$accent-7-dark};
  --accent-8: #{$accent-8-dark};
  --accent-9: #{$accent-9-dark};
  --accent-10: #{$accent-10-dark};
  --accent-11: #{$accent-11-dark};

  --header-color: #{$header-color-dark};
  --header-bg-color: #{$header-bg-color-dark};

  --sidebar-color-: #{$sidebar-color-dark};
  --sidebar-bg-color: #{$sidebar-bg-color-dark};
  --sidebar-secondary-color: #{$sidebar-secondary-color-dark};
  --sidebar-secondary-bg-color: #{$sidebar-secondary-bg-color-dark};

  --navbar-bg-color: #{$navbar-bg-color-dark};
  --navbar-elem-color: #{$navbar-elem-color-dark};
  --navbar-elem-active: #{$navbar-elem-active-dark};
  --navbar-child-elem-color: #{$navbar-child-elem-color-dark};
  --navbar-child-elem-active: #{$navbar-child-elem-active-dark};

  --grid-bg-color: #{$grid-bg-color-dark};
  --unit-color: #{$unit-color-dark};
  --info-color: #{$info-color-dark};

  --std-shadow: #{$std-shadow-dark};
  --lt-shadow: #{$lt-shadow-dark};

  --status-std: #{$status-std-dark};

  --status-ok: #{$status-ok-dark};
  --status-ok-fill: #{$status-ok-fill};
  --status-moderate: #{$status-moderate-dark};
  --status-moderate-fill: #{$status-moderate-fill};
  --status-critical: #{$status-critical-dark};
  --status-critical-fill: #{$status-critical-fill};

  --gear-color: #{$gear-color-dark};
  --flash-color: #{$flash-color-dark};
  --battery-active: #{$battery-active-dark};
  --battery-inactive: #{$battery-inactive-dark};
  --overlay-opacity: #{$overlay-opacity-dark};
}

/*------------------------------------*\
    BLURS
\*------------------------------------*/

@mixin light--blur {
  background-color: rgba(255, 255, 255, 0.95);
  @supports ((-webkit-backdrop-filter: saturate(180%) blur(10px)) or (backdrop-filter: saturate(180%) blur(10px))) {
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(180%) blur(10px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    user-select: none;
  }
}

@mixin dark--blur {
  background-color: rgba(45, 45, 45, 0.98);
  @supports ((-webkit-backdrop-filter: saturate(180%) blur(20px)) or (backdrop-filter: saturate(180%) blur(20px))) {
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    user-select: none;
  }
}

/*------------------------------------*\
    GENERAL VALUES
\*------------------------------------*/
$border-radius: 10px;

/*------------------------------------*\
    NAVBAR VALUES
\*------------------------------------*/
$navWidth: 4rem;
$navInnerWidth: 15rem;
$navIconSize: 27px;

/*------------------------------------*\
    GRID ELEMENT VALUES
\*------------------------------------*/
$value_offsetTop: 4%;
