.heaters {
  position: relative;
  height: 17rem;
  min-width: 17rem;

  .heater.loading {
    opacity: 1;
    .value span {
      opacity: 1;
    }
  }

  .grid--elem {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 1s, transform 0.35s ease !important;

    .value {
      span {
        transition: transform 1s ease, opacity 1s ease, color 0.5s !important;
        opacity: 0;
        transform: translate3d(0, 25%, 0);
      }
    }
    &.show {
      opacity: 1;
      pointer-events: auto;
      .value {
        span {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
    &.shown {
      opacity: 1;
    }
  }
}
