.pools {
  .lighting {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--battery-inactive);

    &.activated {
      fill: var(--status-ok);
    }
  }

  .cover {
    position: absolute;
    top: 0.75rem;
    right: 1rem;
    height: 1.7rem;
    width: 1.7rem;

    .doors {
      fill: var(--battery-inactive);
      opacity: 0.8;
    }

    &.covered {
      .doors {
        opacity: 1;
        fill: var(--status-ok);
      }
    }
  }
}
