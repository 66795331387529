.sidebar__std {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: #{$navWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;
  background: var(--sidebar-bg-color);
  box-shadow: var(--std-shadow);
  z-index: 1;

  @media #{$phone} {
    display: none;
    top: inherit;
    right: 0;
    width: inherit;
    height: 4rem;
    flex-direction: row;
  }

  & > .filter {
    margin-top: auto;

    @media #{$phone} {
      margin-top: 0.75rem;
      margin-left: auto;
    }

    & + .filter {
      margin-top: 0.75rem;
    }
  }

  & > span {
    display: block;
    position: relative;
    cursor: pointer;
    height: $navIconSize;
    width: $navIconSize;
    margin: 0.75rem;

    a {
      color: inherit;
    }

    svg {
      height: 100%;
      width: 100%;

      &.small {
        transform: scale(0.85) translateZ(0);
        transform-origin: center;
      }
    }

    &::after {
      opacity: 0;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--accent-7);
      border-radius: 50%;
      transform-origin: center;
      transform: scale(1.25) translateZ(0);
      z-index: -1;

      transition: transform 0.3s ease;
    }

    &:hover::after,
    &.opened::after {
      opacity: 1;
      transform: scale(1.65) translateZ(0);
    }

    &.opened::after {
      opacity: 1;
      transform: scale(1.65) translateZ(0);
      background-color: var(--accent-4);
    }
  }

  .settings__toggle {
    svg {
      transform: rotate(0deg);
      transition: transform 0.5s ease;
    }
    &.opened {
      svg {
        transform: rotate(-60deg);
      }
    }
  }
}

.sidebar__ext {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: #{$navWidth};
  background: var(--sidebar-secondary-bg-color);
  box-shadow: var(--std-shadow);
  width: #{$navInnerWidth};
  transform: translateX(-#{$navInnerWidth});
  transition: transform 0.5s ease, visibility 0s 0.5s, opacity 0s 0.5s;

  &.opened {
    transition: transform 0.5s ease;
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}
