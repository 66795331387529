.photovoltaik.grid {
    grid-template-columns: repeat(4, minmax(12rem, 16rem));
    grid-template-rows: repeat(2, minmax(10rem, 1fr));

    .grid--elem {
        min-height: inherit;
        background-color: #071c26;
        background: linear-gradient(180deg, #071c26 0%, #1a3245 39%, #2a4163 77%) !important;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        &.active {
            background-color: #f2994a;
            background: linear-gradient(to bottom, #f2994a, #f2c94c) !important;
        }
    }

    svg.day,
    svg.night {
        position: absolute;
        left: 0;
        top: $value_offsetTop;
        width: 100%;
        height: 100%;
        fill: #fff;
        opacity: 0.2;
        pointer-events: none;
    }

    svg.day {
        animation: sun 12s linear infinite;
        transform-origin: center;
    }

    svg.night {
        display: block;
    }

    a,
    span {
        color: #fff !important;
    }

    @media #{$medium} {
        display: grid;
        grid-template-columns: minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(12rem, 1fr) minmax(12rem, 1fr);
        grid-template-rows: repeat(2, minmax(9rem, 1fr));
        gap: 1.25rem;
        padding: 1rem 2rem;

        .grid--elem {
            min-width: auto;
            min-height: auto;
            max-width: inherit !important;
            margin: 0;
        }
    }
}

.pv-total {
    grid-area: 1 / 1 / 3 / 3;

    .value {
        span {
            font-size: 3.5rem !important;
        }
    }

    svg.day,
    svg.night {
        position: absolute;
        top: -22%;
        height: 144%;
        fill: #fff;
        opacity: 0.2;
    }
}

@keyframes sun {
    0% {
        transform: rotate(0deg) scale(1.2);
    }
    12% {
        transform: rotate(45deg) scale(1);
    }
    25% {
        transform: rotate(90deg) scale(1.2);
    }
    37% {
        transform: rotate(135deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(1.2);
    }
    62% {
        transform: rotate(225deg) scale(1);
    }
    75% {
        transform: rotate(270deg) scale(1.2);
    }
    87% {
        transform: rotate(315deg) scale(1);
    }
    100% {
        transform: rotate(360deg) scale(1.2);
    }
}
