.home {
  background: #151515;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  a {
    position: relative;
    font-family: $montserrat;
    font-size: 1.15rem;
    color: #fff;
    padding: 0.75rem 2.75rem;
    border-radius: 100px;
    overflow: hidden;
    mask-image: radial-gradient(white, black);
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    span {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: #272727;
      z-index: -2;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      border-radius: 999px;
      transition: transform 0.4s ease-out;
    }

    &::before {
      background-color: #9df5b4;
      transform: scale(0) translate(100%, 0);
      transform-origin: right center;
    }

    &::after {
      background-color: #51b86c;
      transform: scale(0) translate(-100%, 0);
      transform-origin: left center;
    }

    &:hover {
      &::before,
      &::after {
        transform: scale(1) translate(0, 0);
      }
    }
  }

  h1 {
    color: #fff;
  }
}
