.temperatures {
  .value {
    opacity: 0;
    transition: opacity 1s, transform 0.5s ease;

    span {
      transform: translateY(25%);
      transition: transform 1s ease !important;
    }

    &.show {
      opacity: 1;

      span {
        transform: translateY(0);
      }
    }
  }
}
