.graph {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .tooltip {
    .value {
      padding: 10px;
      border-radius: 3px;
      background-color: rgba(25, 25, 25, 0.9);
      text-align: center;
      margin-bottom: 5px;
    }
    .datetime {
      text-align: center;
      font-weight: 300;
      font-size: 0.8rem;
      border-radius: 3px;
      padding: 7px;
      background-color: rgba(15, 15, 15, 0.5);
    }
  }
}

.time__picker {
  position: absolute;
  top: 1.05rem;
  right: 3.5rem;
  white-space: nowrap;

  li {
    font-size: 0.8rem;
    cursor: pointer;
    list-style: none;
    display: inline-block;
    margin: 0 0.35rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: #353535;
    transition: filter 0.35s;

    &:hover,
    &.active {
      filter: brightness(0.75);
    }

    &.active {
      cursor: default;
    }
  }
}

/* Recharts opts */
.recharts-bar-rectangle {
  .recharts-rectangle {
    transition: fill 0.5s;
  }
}

.recharts-text {
  transform: translateY(7px);
  font-size: 0.825rem;
  fill: #aaa;
}
