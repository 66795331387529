.woodchipboiler {
  .wcbsvg {
    position: absolute;
    bottom: 1.25rem;
    left: 1.25rem;
    height: 28px;
    width: 28px;
    fill: var(--gear-color);

    &.active {
      fill: var(--status-ok);

      .gear {
        animation: rotate 8s linear infinite;
        transform-origin: center;
      }
    }

    .flash {
      transform: scale(0.7);
      transform-origin: center;
    }
  }
}
