.bhkw.grid {
  grid-template-columns: minmax(9rem, 16rem) minmax(9rem, 16rem) minmax(9rem, 16rem) minmax(9rem, 16rem) minmax(
      12rem,
      16rem
    ) minmax(12rem, 16rem);
  grid-template-rows: repeat(2, minmax(10rem, 1fr));

  .grid--elem {
    min-height: inherit;
  }

  .glock {
    .bhkwsvg {
      z-index: -1;
      height: 63%;
      width: 63%;
      position: absolute;
      top: 50% + $value_offsetTop;
      transform: translateY(-50%);
      pointer-events: none;

      .gear {
        fill: var(--gear-color);
      }

      .flash {
        fill: var(--flash-color);
        opacity: 0;
        transform: scale(0.8);
        transform-origin: center;
      }
    }

    &.active {
      .gear {
        animation: rotate 8s linear infinite;
        transform-origin: center;
      }

      .flash {
        animation: flash 1s linear alternate infinite;
      }
    }
  }

  .dachs {
    .bhkwsvg {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      height: 26px;
      width: 26px;
      fill: var(--gear-color);

      .flash {
        transform: scale(0.7);
        transform-origin: center;
      }
    }

    &.active {
      .gear {
        fill: var(--status-ok);
        animation: rotate 8s linear infinite;
        transform-origin: center;
      }

      .flash {
        fill: var(--status-ok);
        animation: flash 1s linear alternate infinite;
      }
    }
  }

  @media #{$medium} {
    display: grid;
    grid-template-columns: minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(12rem, 1fr) minmax(
        12rem,
        1fr
      );
    grid-template-rows: repeat(2, minmax(9rem, 1fr));
    gap: 1.25rem;
    padding: 1rem 2rem;

    .grid--elem {
      min-width: auto;
      min-height: auto;
      max-width: inherit !important;
      margin: 0;
    }
  }

  .glock {
    &.glock1 {
      grid-area: 1 / 1 / 3 / 3;
    }
    &.glock2 {
      grid-area: 1 / 3 / 3 / 5;
    }

    .value {
      span {
        font-size: 3.5rem !important;
      }
    }

    .bhkwsvg {
      height: 65%;
      width: 65%;
      min-height: 12rem;
      min-width: 12rem;
    }
  }
}
