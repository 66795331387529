.network__wrapper {
    padding: 2.5rem 8%;

    /* GRAPHIC */
    #IPS,
    #TA-Unit,
    #TA-Unit-2,
    #TA-Unit-3,
    #LocalAPI,
    #MongoDB,
    #Cliq,
    #Webhook,
    #HE,
    #API {
        cursor: pointer;
        transform: translate3d(0, 0, 0);
        transition: transform 0.5s ease;

        & * {
            stroke: #fff;
            stroke-width: 3px;
            stroke-linejoin: round;
            stroke-opacity: 0;

            transition: stroke-opacity 0.5s ease;
        }

        &:hover {
            transform: translate3d(0.15%, -1.25%, 0);

            & * {
                stroke-opacity: 0.3;
            }
        }
    }
}
