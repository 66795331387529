#he__logo {
  overflow: visible;
  &.loading {
    path {
      stroke-dasharray: 5000;
      stroke-dashoffset: 10500;
      stroke-width: 10;
      fill-opacity: 0;
      animation: logo 10s ease-in-out alternate infinite;
    }
  }
}

@keyframes logo {
  to {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }
}
