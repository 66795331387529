.heatpump {
  .value {
    span {
      margin-bottom: 0 !important;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        border-radius: 10px;
        top: 0;
        bottom: 0;
        animation: none;
      }

      &::before {
        left: -2rem;
        background-color: #1f61c4;
      }

      &::after {
        right: -2rem;
        background-color: #e23e3e;
        animation-delay: 0.8s;
      }
    }
  }

  &.active {
    .value {
      span {
        &::before,
        &::after {
          animation: blink 0.8s linear infinite alternate;
        }
      }
    }
  }
}
