header {
  position: fixed;
  top: 1.5rem;
  left: $navWidth;
  right: 0;
  height: 4rem;
  box-shadow: var(--std-shadow);
  padding: 0 2.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--header-bg-color);
  margin: 0 3.75rem;
  max-width: 19rem;
  border-radius: $border-radius;
  transition: left 0.5s ease;
  z-index: 9;

  .beta {
    &::before {
      content: "Beta";
      display: inline-block;
      padding: 3px 7px;
      background: $main-color-darkened;
      color: #fff;
      border-radius: 3px;
      margin-right: 10px;
      font-size: 0.5rem;
      transform: translateY(-4px);
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  &.opened {
    left: calc(#{$navWidth} + #{$navInnerWidth});
  }

  @media #{$medium} {
    margin: 0 3.5rem;
  }

  @media #{$phone} {
    left: 0;
    top: 0;
    padding: 0 1.5rem;
    margin: 0;
    border-radius: 0;
    max-width: inherit;
  }

  h1 {
    font-size: 1.25rem;
    font-family: $nunito;
    font-weight: 700;
    letter-spacing: 0;
  }

  a {
    color: var(--header-color);
  }
}
