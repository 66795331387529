.soc {
    .grid--elem {
        max-width: 35rem;

        .unit {
            color: var(--main-fg) !important;
        }

        @media #{$medium} {
            width: 100%;
        }

        .battery__status {
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            max-height: 100%;
            opacity: 0.75;
            transition: height 1.5s ease, background-color 1s;
        }

        &.ok {
            .battery__status {
                background-color: var(--status-ok-fill);
            }
        }
        &.moderate {
            .battery__status {
                background-color: var(--status-moderate-fill);
            }
        }
        &.critical {
            .value {
                span {
                    animation: warning 0.5s ease-in-out infinite alternate;
                }
            }
            .battery__status {
                background-color: var(--status-critical-fill);
            }
        }
    }
}
