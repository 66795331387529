@mixin fadeIn($name: "fadeIn", $from: 0, $to: 1, $time: 1s, $easing: ease, $delay: 0s) {
    @keyframes #{$name} {
        0% {
            opacity: $from;
        }
        100% {
            opacity: $to;
        }
    }

    animation: #{$name} $time $delay $easing;
}

@mixin fadeInX($name: "fadeInX", $distance: 20px, $time: 1s, $easing: ease, $delay: 0s) {
    @keyframes #{$name} {
        0% {
            opacity: 0;
            transform: translate3d($distance, 0, 0);
        }
        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    animation: #{$name} $time $delay $easing;
}

@mixin fadeInY($name: "fadeInY", $distance: 20px, $time: 1s, $easing: ease, $delay: 0s) {
    @keyframes #{$name} {
        0% {
            opacity: 0;
            transform: translate3d(0, $distance, 0);
        }
        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    animation: #{$name} $time $delay $easing;
}

@mixin fadeInScale($name: "fadeInScale", $from: 0, $to: 1, $time: 1s, $easing: ease, $delay: 0s) {
    @keyframes #{$name} {
        0% {
            opacity: 0;
            transform: scale($from);
        }
        100% {
            opacity: 1;
            transform: scale($to);
        }
    }

    animation: #{$name} $time $delay $easing;
}

@mixin rotate($name: "rotate", $start: 0deg, $end: 360deg, $time: 1s, $after: forwards, $easing: ease, $delay: 0s) {
    @keyframes #{$name} {
        0% {
            transform: rotate($start);
        }
        100% {
            transform: rotate($end);
        }
    }

    animation: #{$name} $time $delay $easing $after;
}

@keyframes warning {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.15);
    }
}
