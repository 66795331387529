.settings {
    cursor: default;
    position: absolute;
    bottom: -10px;
    left: $navIconSize + 18px;
    width: 300px;
    height: auto;
    border-radius: $border-radius;
    background: var(--accent-6);
    box-shadow: 0 0 24px 10px rgba(0, 0, 0, 0.15);

    @include fadeInY("fadeInY10and05", 10px, 0.5s);

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
        }
    }
}
