.co2 {
  .grid--elem {
    max-width: 35rem;

    @media #{$medium} {
      width: 100%;
    }
  }
  .value {
    span {
      color: var(--main-color) !important;
    }
  }
}
