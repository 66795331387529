.screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  width: inherit;

  .screen__content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, minmax(auto, 33.3%));
    padding: 1% 2%;
  }

  .grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    gap: 1vw;
    padding: 1vw;

    &.soc {
      .grid--elem {
        .value {
          span {
            font-size: 3.5vw !important;
          }
        }
      }
    }

    &.bhkw {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr 1.25fr;

      .glock {
        .value {
          span {
            font-size: 2.75vw !important;
          }
        }
        .bhkwsvg {
          height: 75%;
          width: 100%;
          min-height: inherit;
          min-width: inherit;
        }
      }

      .dachs {
        .value {
          top: 55%;
          span {
            font-size: 2vw !important;
          }
        }
        span.name {
          padding: 5%;
          padding-right: 25%;
        }
        .unit {
          bottom: 6%;
          right: 6%;
        }
        .bhkwsvg {
          bottom: 0;
          left: 0;
          margin: 5%;
          width: 1.1vw;
          height: 1.1vw;
        }
      }
    }

    &.photovoltaik {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr;

      .pv-total {
        .value {
          span {
            font-size: 3.5vw !important;
          }
        }
      }
    }

    &.chargingstation {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr;

      .name {
        padding-right: 0.75vw !important;

        > a {
          margin-right: 4%;
          height: 0.8vw;
          width: 0.8vw;
        }

        .public {
          margin-left: auto;
          font-size: 0.5vw;
        }
      }
    }

    &.heat {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      .bufferstorage.loading {
        grid-column: span 2;
      }

      .woodchipboiler,
      .temperatures {
        grid-column: span 2;
      }

      .heaters {
        height: auto;
        min-width: inherit;
        .grid--elem {
          // height: 100%;
        }
      }
    }

    .grid--elem {
      max-width: inherit !important;
      min-width: inherit !important;
      max-height: inherit !important;
      min-height: inherit !important;
      margin: 0;
      padding: 0;
      display: inline-flex;
      overflow: hidden;
      border-radius: 0.35vw;
      border: 1px solid #303030;

      &:not(.loading) {
        @include fadeInScale('fadeInScale025and1', 0.25, 1, 1s);
      }

      .value {
        span {
          font-size: 2.25vw !important;
          margin-bottom: 0 !important;
        }
      }

      span.name {
        font-size: 0.7vw !important;
        padding: 0.75vw;
        padding-right: 25%;
        transform: translateZ(0);
      }

      .info {
        top: 0;
        right: 0;
        margin: 0.75vw;
        width: 0.9vw;
        height: 0.9vw;
        border-radius: 999px;
        border: 1px solid;
        font-size: 0.55vw !important;
      }

      .unit {
        bottom: 0.75vw;
        right: 0.75vw;
        font-size: 0.65vw !important;
      }

      svg.status,
      .wcbsvg {
        bottom: 0;
        left: 0;
        margin: 0.75vw;
        width: 1.25vw;
        height: 1.25vw;
      }
    }
  }

  .housekeeping {
    svg#washing {
      width: 4vw;
      margin-top: 10%;
    }
  }

  .pools {
    .lighting {
      height: 1.25vw;
      width: 1.25vw;
      bottom: 0.75vw;
      left: 0.75vw;
    }

    .cover {
      height: 1.25vw;
      width: 1.25vw;
      top: 0.55vw;
      right: 0.75vw;
    }
  }

  .multi {
    display: grid;
    gap: 1vw;
    padding: 1vw;
    grid-template-columns: 1fr 1fr;
    & > .grid {
      padding: 0;
      overflow: visible;
    }
  }

  .custom {
    grid-template-rows: 1fr 1fr;

    & > * {
      grid-template-columns: 1fr 1fr;
    }
  }
}
