.detailed__view {
    z-index: 999;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;

    animation: appear 0.3s ease forwards;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        z-index: -1;
    }

    .inner {
        display: flex;
        flex-direction: column;
        position: absolute;
        height: 90%;
        width: 90%;
        min-height: 20rem;
        max-height: 48rem;
        border-radius: 10px;
        background-color: #101010;
        overflow-y: scroll;

        opacity: 0;
        transform: scale(0.9) translate3d(0, 0, 0);

        animation: appear 0.75s 0.1s ease forwards;

        @media #{$small} {
            height: 80%;
        }

        .close {
            z-index: 2;
            position: fixed;
            top: 0;
            right: 0;
            display: block;
            cursor: pointer;
            font-size: 1.6rem;
            width: 45px;
            height: 40px;
            background-color: #272727;
            border-radius: 0px 10px 0px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: scale(1) translate3d(0, 0, 0);
            transition: transform 0.3s ease, background-color 0.3s, border-radius 0.3s ease;

            &:hover {
                transform: scale(0.9) translate3d(0, 0, 0);
                background-color: #222;
                border-radius: 10px;
            }
        }

        .options {
            z-index: 1;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 1rem;
            height: 4rem;
            white-space: nowrap;

            & > * {
                //display: inline-block;
                font-size: 0.8rem;
                padding: 8px 12px;
                border-radius: 5px;
                background-color: #202020;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            & > *:not(:last-child) {
                margin-right: 10px;
            }

            .current {
                position: relative;
                z-index: 0;
                overflow: hidden;

                .battery__status {
                    z-index: -1;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0.65;
                    transition: height 1.5s ease, background-color 1s;

                    &.ok {
                        background-color: var(--status-ok-fill);
                    }
                    &.moderate {
                        background-color: var(--status-moderate-fill);
                    }
                    &.critical {
                        background-color: var(--status-critical-fill);
                    }
                }
            }

            .view,
            .refetch {
                z-index: 1;
                cursor: pointer;
                transform: scale(1) translate3d(0, 0, 0);
                transition: transform 0.3s ease, background-color 0.3s;

                @media (hover: hover) {
                    &:hover {
                        transform: scale(0.96) translate3d(0, 0, 0);
                        background-color: #171717;
                    }
                }

                @media (hover: none) {
                    &:hover {
                        background-color: #252525;
                    }
                }
            }

            @media #{$small} {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                justify-content: center;
                background: #151515;
                border-radius: 0 0 10px 10px;

                & > *:nth-last-child(2) {
                    margin-right: 0;
                }

                .refetch {
                    position: fixed;
                    top: 0;
                    left: 0;
                    border-radius: 10px 0 10px 0;
                    width: 45px;
                    height: 40px;
                    background-color: #272727;
                }
            }
        }

        .name {
            padding: 0 2rem 0.75rem;
            text-align: center;
            transform: translateZ(0);

            @media #{$small} {
                padding: 1.75rem 3.5rem 0.75rem;
                line-height: 1;
            }
        }

        .param {
            list-style: none;
            text-align: center;
            margin-bottom: 2.5%;

            li {
                cursor: pointer;
                display: inline-block;
                padding: 0.5rem 1.5rem;
                border-radius: 3px;
                margin: 0 0.5rem;

                background-color: #202020;
                transform: scale(1) translate3d(0, 0, 0);

                transition: transform 0.275s ease, background-color 0.3s;

                &:hover,
                &.checked {
                    background-color: #171717;
                    transform: scale(0.97) translate3d(0, 0, 0);
                }

                @media #{$small} {
                    margin: 0.25rem;
                    font-size: 0.85rem;
                    padding: 0.4rem 1rem;
                }
            }
        }

        .graph__wrapper {
            position: relative;
            flex: 1 0 auto;
            display: flex;
            align-items: flex-end;
            z-index: -1;
            overflow: hidden;

            @media #{$small} {
                margin-bottom: 4rem;

                .graph {
                    max-height: 15rem;
                    top: inherit;
                    transform: scale(1.025) translate3d(0, 0, 0);
                }
            }
        }
    }
}

.co2 {
    @media #{$small} {
        .detailed__view {
            .options {
                height: 0;
                padding: 0;
            }
            .graph__wrapper {
                margin-bottom: 0;
            }
        }
    }
}

@keyframes appear {
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
}
